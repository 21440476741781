
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import KTTableWidgetHealthRatios from "@/components/widgets/tables/WidgetHealthRatios.vue";

export default defineComponent({
  name: "documents",
  components: {
    KTTableWidgetHealthRatios,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Financial Healt Ratio", ["Customer"]);
    });
  },
});
